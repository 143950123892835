import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const ListsTableRow = ({ id, name, number, email, lastUpdated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    id,
    name,
    number,
    email,
    lastUpdated,
    status: true, // Default status
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = e => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated data to the console
    console.log("Updated Data:", data);
    setIsEditing(false);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <tr
      data-id={data.id}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="id" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.id}
            onChange={e => setData({ ...data, id: e.target.value })}
          />
        ) : (
          data.id
        )}
      </td>
      <td data-field="name" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.name}
            onChange={e => setData({ ...data, name: e.target.value })}
          />
        ) : (
          data.name
        )}
      </td>
      <td data-field="number" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.number}
            onChange={e => setData({ ...data, number: e.target.value })}
          />
        ) : (
          data.number
        )}
      </td>
      <td data-field="email" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.email}
            onChange={e => setData({ ...data, email: e.target.value })}
          />
        ) : (
          data.email
        )}
      </td>
      <td width={"10%"}>{data.lastUpdated}</td>
      <td width={"10%"}>
        <div className="square-switch">
          <input
            type="checkbox"
            id="square-switch1"
            switch="bool"
            checked={data.status}
            onChange={e => setData({ ...data, status: e.target.checked })}
          />
          <label
            htmlFor="square-switch1"
            data-on-label="Yes"
            data-off-label="No"
          />
        </div>
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm "
            title="Edit"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" title="Edit" />
          </button>
        )}
        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
        >
          <i className="fas fa-trash-alt" title="Delete" />
        </button>
      </td>
    </tr>
  );
};

export default ListsTableRow;
