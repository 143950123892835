import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import ReferralTableRow from "../components/ReferralTableRow";

const records = [
  {
    id: 1,
    name: "Abdul Rehman",
    email: "admin@gmail.com",
    number: "554455",
    date: "27-01-2023",
  },
  {
    id: 2,
    name: "John Doe",
    email: "johndoe@example.com",
    number: "1234567890",
    date: "28-01-2023",
  },
  {
    id: 3,
    name: "Alice Smith",
    email: "alice@example.com",
    number: "9876543210",
    date: "29-01-2023",
  },
  {
    id: 4,
    name: "Emma Johnson",
    email: "emma@example.com",
    number: "5555555555",
    date: "30-01-2023",
  },
  {
    id: 5,
    name: "Michael Brown",
    email: "michael@example.com",
    number: "7777777777",
    date: "31-01-2023",
  },
  {
    id: 6,
    name: "Sophia Davis",
    email: "sophia@example.com",
    number: "6666666666",
    date: "01-02-2023",
  },
  {
    id: 7,
    name: "Daniel Wilson",
    email: "daniel@example.com",
    number: "4444444444",
    date: "02-02-2023",
  },
  {
    id: 8,
    name: "Olivia Lee",
    email: "olivia@example.com",
    number: "2222222222",
    date: "03-02-2023",
  },
  {
    id: 9,
    name: "James Martin",
    email: "james@example.com",
    number: "9999999999",
    date: "04-02-2023",
  },
  {
    id: 10,
    name: "Ava White",
    email: "ava@example.com",
    number: "8888888888",
    date: "05-02-2023",
  },
];

const Referral = () => {
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);

  const handleCheckClick = () => {};
  const handleReinviteClick = () => {};

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Referrals</h4>
                <div className="page-title-right">
                  <DefaultView />
                  <div className="d-flex justify-content-sm-end pt-20">
                    <Link
                      to="/referral-invitation-form"
                      className="btn btn-primary"
                    >
                      Add Invite
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card marginExtra">
                  <div className="card-header ">
                    <div className="row responsSearch">
                      <div className="col-md-6">
                        <h4 className="card-title">List View</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      <div
                        id="datatable-buttons_filter"
                        className="col-sm-4 dataTables_filter"
                      >
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            aria-controls="datatable-buttons"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-editable table-nowrap align-middle table-edits">
                        <thead>
                          <tr style={{ cursor: "pointer" }}>
                            <th>Referral Name</th>
                            <th>Email</th>
                            <th>Fax Number</th>
                            <th>Status</th>
                            <th>Invite Datetime</th>
                            <th>Referral Signup</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRecords.map((record) => (
                            <ReferralTableRow
                              key={record.id}
                              name={record.name}
                              email={record.email}
                              number={record.number}
                              date={record.date}
                              onCheckClick={handleCheckClick}
                              onReinviteClick={handleReinviteClick}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="datatable-buttons_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable-buttons_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="datatable-buttons_previous"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={0}
                            tabIndex={0}
                            className="page-link"
                          >
                            Previous
                          </Link>
                        </li>
                        <li className="paginate_button page-item active">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            1
                          </Link>
                        </li>
                        {/* Add more pagination links here */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

export default Referral;
