import axios from "axios";
import { toast as message } from "react-toastify";
import * as actionTypes from "../action";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const actionGetSentMessages =
  (offset = 0, msgType = null, important = null, q = null, limit = 20) =>
  async (dispatch, getState) => {
    console.log("actionGetSentMessages calling");
    dispatch({ type: actionTypes.GET_SENT_MESSAGES_LOADER, payload: true });

    try {
      const url = `${BASE_URL}/inbox/get-sent-msgs?limit=${limit}&offset=${offset}${
        q ? `&search=${q}` : ""
      }${msgType ? `&msg_status=${msgType}` : ""}${
        important == "YES" ? `&is_important=${"Yes"}` : ""
      }`;

      let res = await axios.get(url);

      dispatch({
        type: actionTypes.GET_SENT_MESSAGES_LOADER,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        dispatch({
          type: actionTypes.SENT_MESSAGES_DATA,
          payload: res.data.result.data,
        });
        console.log(res.data.result.data, "res.data.result.data");
      } else {
        console.log(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }

      dispatch({
        type: actionTypes.GET_SENT_MESSAGES_LOADER,
        payload: false,
      });
    }
  };

export const actionMarkAddImportantSentMessage =
  (data, messages) => async (dispatch, getState) => {
    console.log("actionMarkAddImportantSentMessage calling");
    dispatch({
      type: actionTypes.MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES,
      payload: true,
    });

    try {
      let res = await axios.patch(`${BASE_URL}/inbox/important-sent/add`, data);
      dispatch({
        type: actionTypes.MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_ADD_IMPORTANT_SENT_MESSAGES,
          payload: messages,
        });
        message.success("Importance marked successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }
      dispatch({
        type: actionTypes.MARK_ADD_IMPORTANT_LOADER_SENT_MESSAGES,
        payload: false,
      });
    }
  };

export const actionMarkRemoveImportantSentMessage =
  (data, messages, params) => async (dispatch, getState) => {
    console.log("actionMarkRemoveImportantSentMessage calling");
    dispatch({
      type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES,
      payload: true,
    });

    try {
      let res = await axios.patch(
        `${BASE_URL}/inbox/important-sent/remove`,
        data
      );
      dispatch({
        type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_REMOVE_IMPORTANT_SENT_MESSAGES,
          payload: messages,
        });
        params.isImportant &&
          dispatch(
            actionGetSentMessages(
              params.offset,
              params.msgType,
              params.isImportant ? "YES" : "",
              params.searchText ? params.searchText : ""
            )
          );
        message.success("Remove From Importance successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }
      dispatch({
        type: actionTypes.MARK_REMOVE_IMPORTANT_LOADER_SENT_MESSAGES,
        payload: false,
      });
    }
  };

export const actionMarkAddStarredSentMessage =
  (data) => async (dispatch, getState) => {
    console.log("actionMarkAddStarredSentMessage calling");

    try {
      let res = await axios.patch(`${BASE_URL}/inbox/starred-sent/add`, data);
      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_ADD_STARRED_SENT_MESSAGES,
          payload: data,
        });
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }
    }
  };

export const actionMarkRemoveStarredSentMessage =
  (data) => async (dispatch, getState) => {
    console.log("actionMarkRemoveStarredSentMessage calling");
    try {
      let res = await axios.patch(
        `${BASE_URL}/inbox/starred-sent/remove`,
        data
      );

      if (parseInt(res.data.http_status_code) == 201) {
        dispatch({
          type: actionTypes.MARK_REMOVE_STARRED_SENT_MESSAGES,
          payload: data,
        });
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }
    }
  };

export const actionMarkTrashSentMessage =
  (data, params, setShowEmailDetailID) => async (dispatch, getState) => {
    console.log("actionMarkTrashSentMessage calling", params);
    dispatch({
      type: actionTypes.MARK_TRASH_LOADER_SENT_MESSAGE,
      payload: true,
    });

    try {
      let res = await axios.post(`${BASE_URL}/inbox/trash_sent_msgs`, data);
      dispatch({
        type: actionTypes.MARK_TRASH_LOADER_SENT_MESSAGE,
        payload: false,
      });
      if (parseInt(res.data.http_status_code) == 200) {
        setShowEmailDetailID && setShowEmailDetailID("");
        dispatch(
          actionGetSentMessages(
            params.offset,
            params.msgType,
            params.isImportant ? "YES" : "",
            params.searchText ? params.searchText : ""
          )
        );
        message.success("send to trash successfully.");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }
      dispatch({
        type: actionTypes.MARK_TRASH_LOADER_SENT_MESSAGE,
        payload: false,
      });
    }
  };

export const actionGetFaxQueues =
  (msgUserID, msgType = "OUT") =>
  async (dispatch) => {
    console.log("actionGetFaxQueues calling");
    dispatch({ type: actionTypes.GET_FAX_QUEUES_LOADER, payload: true });

    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SENDFAX_BASE_URL}/sendfax/get-fax-queues?msg_userID=${msgUserID}&msg_type=${msgType}`
      );

      console.log({ res });

      dispatch({
        type: actionTypes.GET_FAX_QUEUES_LOADER,
        payload: false,
      });

      const faxQueues = res.data;

      dispatch({
        type: actionTypes.FAX_QUEUES_DATA,
        payload: faxQueues,
      });
      console.log(faxQueues, "Fax queues data received");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred");
      }

      dispatch({
        type: actionTypes.GET_FAX_QUEUES_LOADER,
        payload: false,
      });
    }
  };

export const actionGetFaxQueuesCount = (msgUserID) => async (dispatch) => {
  console.log("actionGetFaxQueuesCount calling");
  dispatch({ type: actionTypes.GET_FAX_QUEUES_COUNT_LOADER, payload: true });

  try {
    // Get OUT count
    let outRes = await axios.get(
      `${process.env.REACT_APP_SENDFAX_BASE_URL}/sendfax/get-fax-queues?msg_userID=${msgUserID}&msg_type=OUT`
    );
    const outFaxQueues = outRes.data;

    // Get IN count
    let inRes = await axios.get(
      `${process.env.REACT_APP_SENDFAX_BASE_URL}/sendfax/get-fax-queues?msg_userID=${msgUserID}&msg_type=IN`
    );
    const inFaxQueues = inRes.data;

    dispatch({
      type: actionTypes.GET_FAX_QUEUES_COUNT_LOADER,
      payload: false,
    });

    dispatch({
      type: actionTypes.FAX_QUEUES_COUNT_DATA,
      payload: { inFaxQueues, outFaxQueues },
    });
    console.log({ inFaxQueues, outFaxQueues }, "Fax queues data received");
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }

    dispatch({
      type: actionTypes.GET_FAX_QUEUES_COUNT_LOADER,
      payload: false,
    });
  }
};
