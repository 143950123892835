import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import DefaultView from "../components/DefaultView";
import EmailLeftbar from "../components/EmailLeftbar";
import {
  actionGetReceivedMessages,
  actionMarkAddImportant,
  actionMarkAddStarred,
  actionMarkReadMessages,
  actionMarkRemoveImportant,
  actionMarkRemoveStarred,
  actionMarkTrashMessage,
  actionMarkUnreadMessages,
  actionGetMessageDetails,
  actionInboxDetailPageSetIsRead,
  actionRemoveMessageFromTrash,
} from "../store/actions/inboxAction";
import {
  actionMarkTrashSentMessage,
  actionMarkAddImportantSentMessage,
  actionMarkRemoveImportantSentMessage,
} from "../store/actions/sentFaxesAction";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ReadEmail = memo(
  ({
    receivedMessages,
    sentMessages,
    actionMarkAddImportant,
    actionMarkRemoveImportant,
    addImportantLoader,
    removeImportantLoader,
    markReadLoader,
    markUnreadLoader,
    markTrashLoader,
    actionMarkReadMessages,
    actionMarkUnreadMessages,
    actionMarkTrashMessage,
    actionMarkTrashSentMessage,
    messageDetailLoader,
    messageDetailData,
    actionGetMessageDetails,
    actionInboxDetailPageSetIsRead,
    detailId,
    setShowEmailDetailID,
    type,
    getMessageParams,
    actionRemoveMessageFromTrash,
    actionMarkAddImportantSentMessage,
    actionMarkRemoveImportantSentMessage,
    addSentMessageImportantLoader,
    removeSentMessageImportantLoader,
    markSendTrashMessageLoader,
    removeFromTrashLoader,
  }) => {
    // const ID = useParams();
    // console.log(detailId);
    console.log(getMessageParams);
    console.log({ messageDetailData });

    useEffect(() => {
      console.log({ type });
      if (detailId) {
        actionGetMessageDetails(detailId, type);
        if (type == "INBOX") {
          actionInboxDetailPageSetIsRead(detailId);
        }
      }
    }, [detailId]);

    /* Button Group for receive message (Inbox) start*/
    const handleMarkReadSelected = () => {
      actionMarkReadMessages({
        recvd_msg_ID: [detailId],
        select_all: "NO",
      });
    };

    const handleMarkUnReadSelected = () => {
      actionMarkUnreadMessages({
        recvd_msg_ID: [detailId],
        select_all: "NO",
      });
    };

    const handleMarkAddImportant = () => {
      actionMarkAddImportant(
        {
          recvd_msg_ID: [detailId],
          select_all: "NO",
        },
        receivedMessages,
        getMessageParams
      );
    };

    const handleMarkAddImportantSent = () => {
      actionMarkAddImportantSentMessage(
        {
          sent_msg_ID: [detailId],
          select_all: "NO",
        },
        receivedMessages,
        getMessageParams
      );
    };

    const handleMarkTrash = () => {
      actionMarkTrashMessage(
        {
          recvd_msg_ID: [detailId],
        },
        getMessageParams,
        setShowEmailDetailID
      );
    };
    /* Button Group for receive message (Inbox) end*/

    /* Button Group for send message (Outbox,sent,failed,scheduled) start*/
    const handleMarkRemoveImportantSent = () => {
      actionMarkRemoveImportantSentMessage(
        {
          sent_msg_ID: [detailId],
          select_all: "NO",
        },
        sentMessages,
        getMessageParams
      );
    };

    const handleMarkRemoveImportant = () => {
      actionMarkRemoveImportant(
        {
          recvd_msg_ID: [detailId],
          select_all: "NO",
        },
        sentMessages,
        getMessageParams
      );
    };

    const handleSendMarkTrash = () => {
      actionMarkTrashSentMessage(
        {
          sent_msg_ID: [detailId],
        },
        getMessageParams,
        setShowEmailDetailID
      );
    };

    /* Button Group for send message (Outbox,sent,failed,scheduled) start*/

    /* Button Group for trash message (Trash) start*/
    const handleRemoveFromTrash = () => {
      actionRemoveMessageFromTrash(
        {
          trash_msg_ID: [detailId],
        },
        getMessageParams,
        setShowEmailDetailID
      );
    };
    /* Button Group for trash message (Trash) end*/

    const onError = (e) => {
      console.log(e, "error in file-viewer");
    };

    function extractRouteAndFileName(url) {
      var result = {
        route: "",
        fileName: "",
      };

      // Split the URL by "/"
      var urlParts = url.split("/");

      // Find the index of "public" in the array
      var publicIndex = urlParts.indexOf("public");

      // If "public" is found, extract the route after it
      if (publicIndex !== -1 && publicIndex < urlParts.length - 1) {
        result.route = urlParts.slice(publicIndex + 1).join("/");

        // Extract the file name
        result.fileName = urlParts[urlParts.length - 1];
      } else {
        console.log("Invalid URL format");
      }

      return result;
    }

    const FileViewer = ({ fileName }) => {
      const getTrimmedFileName = (fileName) => {
        // Split the file name by '.' to separate the name and extension
        const parts = fileName.split(".");

        // Remove the last part (which is the extension) and join the remaining parts
        const trimmedName = parts.slice(0, -1).join(".");
        const extension = parts[parts.length - 1];

        return { name: trimmedName, extension: extension };
      };

      const { name, extension } = getTrimmedFileName(fileName);

      const renderContent = () => {
        console.log({ extension });
        if (["png", "jpeg", "jpg", "gif", "bmp"].includes(extension)) {
          return (
            <>
              <i
                class="fa fa-file-image"
                style={{ fontSize: 100, margin: "auto", padding: 10 }}
              ></i>
              <div className="py-2 text-center d-flex justify-content-around align-items-center">
                <a
                  href={`${BASE_URL}/files/${fileName}`}
                  className="fw-medium"
                  target="_blank"
                >
                  Preview
                </a>
              </div>
            </>
          );
        } else if (extension === "pdf") {
          return (
            <>
              {/* <i
                class="fa fa-file-pdf"
                style={{ fontSize: 100, margin: "auto", padding: 10 }}
              ></i> */}
              <embed
                src={`${BASE_URL}/files/${fileName}`}
                type="application/pdf"
                width="100%"
                height="auto"
              />
              <div className="py-2 text-center d-flex justify-content-around align-items-center">
                <a
                  href={`${BASE_URL}/files/${fileName}`}
                  className="fw-medium"
                  target="_blank"
                >
                  Preview
                </a>
              </div>
            </>
          );
        } else if (["csv", "xlsx", "doc", "docx", "xls"].includes(extension)) {
          return (
            <>
              <i
                class="fa fa-file-excel"
                style={{ fontSize: 100, margin: "auto", padding: 10 }}
              ></i>
              <div className="py-2 text-center d-flex justify-content-around align-items-center">
                <a
                  href={`${BASE_URL}/files/${fileName}`}
                  className="fw-medium"
                  target="_blank"
                >
                  Preview
                </a>
              </div>
            </>
          );
        } else {
          // Render content for unknown file types
          return (
            <>
              <i
                class="fa fa-file"
                style={{ fontSize: 100, margin: "auto", padding: 10 }}
              ></i>
              <div className="py-2 text-center d-flex justify-content-around align-items-center">
                <a href={`${BASE_URL}/files/${fileName}`} className="fw-medium">
                  Download
                </a>
              </div>
            </>
          );
        }
      };

      return (
        <div className="col-xl-2 col-6">
          <div className="card">{renderContent()}</div>
        </div>
      );
    };
    // const file =
    //   "https://allinpdf.com/RESULT/2024%2f01%2f02%2fd3bc55b8-07e7-45a6-97c4-cb7dbbb311ae.png-0001.pdf";
    // const type = "pdf";

    return (
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Email Inbox</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>

          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* Left sidebar */}
              <EmailLeftbar />
              {/* End Left sidebar */}
              <>
                {/* Right Sidebar */}
                <div className="email-rightbar mb-3">
                  <div className="card">
                    {messageDetailLoader && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: 200,
                        }}
                      >
                        <h5>Loading data..</h5>
                      </div>
                    )}
                    {!messageDetailLoader && messageDetailData && (
                      <>
                        <div className="btn-toolbar gap-2 p-3" role="toolbar">
                          <div
                            className="px-2 my-auto"
                            onClick={() => setShowEmailDetailID("")}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className="fa fa-long-arrow-left fa-lg"
                              title="Back Icon"
                            />
                          </div>
                          <div className="btn-group">
                            {type == "INBOX" && (
                              <>
                                <button
                                  onClick={handleMarkReadSelected}
                                  title="Mark Read"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {markReadLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-envelope-open"></i>
                                  )}
                                </button>
                                <button
                                  onClick={handleMarkUnReadSelected}
                                  title="Mark Unread"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {markUnreadLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-envelope"></i>
                                  )}
                                </button>
                                <button
                                  onClick={handleMarkAddImportant}
                                  title="Mark Important"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {addImportantLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-exclamation-circle" />
                                  )}
                                </button>
                                <button
                                  onClick={handleMarkRemoveImportant}
                                  title="Mark Not Important"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {removeImportantLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-times" />
                                  )}
                                </button>
                                <button
                                  onClick={handleMarkTrash}
                                  title="Move to trash"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {markTrashLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="far fa-trash-alt" />
                                  )}
                                </button>
                              </>
                            )}
                            {type == "SENT-FAX" && (
                              <>
                                <button
                                  onClick={handleMarkAddImportantSent}
                                  title="Mark Important"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {addSentMessageImportantLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-exclamation-circle" />
                                  )}
                                </button>
                                <button
                                  onClick={handleMarkRemoveImportantSent}
                                  title="Mark Not Important"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {removeSentMessageImportantLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="fa fa-times" />
                                  )}
                                </button>
                                <button
                                  onClick={handleSendMarkTrash}
                                  title="Move to trash"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {markSendTrashMessageLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="far fa-trash-alt" />
                                  )}
                                </button>
                              </>
                            )}
                            {type == "TRASH" && (
                              <>
                                <button
                                  onClick={handleRemoveFromTrash}
                                  title="Move to trash"
                                  type="button"
                                  className="btn btn-primary waves-light waves-effect"
                                >
                                  {removeFromTrashLoader ? (
                                    <i
                                      className="fas fa-spinner fa-spin"
                                      title="Loading"
                                    ></i>
                                  ) : (
                                    <i className="far fa-trash-alt" />
                                  )}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="d-flex align-items-top mb-4 justify-content-between">
                            <div className="name-mail-wrap">
                              <div className="flex-grow-1">
                                <h5 className="font-size-14 mb-0">{`Message From:- ${
                                  messageDetailData.msg &&
                                  messageDetailData.msg.msg_senderName
                                }`}</h5>
                                <small className="text-muted">{`From Number:- ${
                                  messageDetailData.msg &&
                                  messageDetailData.msg.msg_fromNumber
                                }`}</small>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {messageDetailData &&
                              messageDetailData.doc &&
                              messageDetailData.doc.map((data, index) => (
                                <FileViewer
                                  fileName={data.doc_direction}
                                  key={index}
                                />
                              ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* card */}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    getReceivedMessagesLoader: state.inbox.getReceivedMessagesLoader,
    receivedMessages: state.inbox.receivedMessages,
    receivedMessagesCount: state.inbox.receivedMessagesCount,
    addImportantLoader: state.inbox.addImportantLoader,
    removeImportantLoader: state.inbox.removeImportantLoader,
    markReadLoader: state.inbox.markReadLoader,
    markUnreadLoader: state.inbox.markUnreadLoader,
    markTrashLoader: state.inbox.markTrashLoader,
    messageDetailLoader: state.inbox.messageDetailLoader,
    messageDetailData: state.inbox.messageDetailData,
    sentMessages: state.sentFax.sentMessages,
    addSentMessageImportantLoader: state.sentFax.addSentMessageImportantLoader,
    removeSentMessageImportantLoader:
      state.sentFax.removeSentMessageImportantLoader,
    markSendTrashMessageLoader: state.sentFax.markSendTrashMessageLoader,
    removeFromTrashLoader: state.inbox.removeFromTrashLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetReceivedMessages,
  actionMarkAddImportant,
  actionMarkRemoveImportant,
  actionMarkReadMessages,
  actionMarkUnreadMessages,
  actionMarkAddStarred,
  actionMarkRemoveStarred,
  actionMarkTrashMessage,
  actionMarkTrashSentMessage,
  actionGetMessageDetails,
  actionInboxDetailPageSetIsRead,
  actionRemoveMessageFromTrash,
  actionMarkAddImportantSentMessage,
  actionMarkRemoveImportantSentMessage,
})(ReadEmail);
