import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// import "choices.js/public/assets/styles/choices.min.css";
import Choices from "choices.js";
import DefaultView from "../components/DefaultView";
import { connect } from "react-redux";
import { toast as message } from "react-toastify";
import { useNavigate } from "react-router-dom";

const animatedComponents = makeAnimated();

const FaxPhoneNumbers = ({ user }) => {
  const TWILLIO_BASE_URL = process.env.REACT_APP_TWILLIO_BASE_URL;
  console.log({ TWILLIO_BASE_URL });
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const selectOptionsRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState("USA");
  const [areaCode, setAreaCode] = useState("");
  const [numberResult, setNumberResult] = useState(null);
  const [searchNumberLoader, setSearchNumberLoader] = useState(false);
  const [getSystemConfigLoader, setGetSystemConfigLoader] = useState(false);
  const [buyNumberLoader, setBuyNumberLoader] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [systemConfig, setSystemConfig] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isAutoSuggestNumber, setIsAutoSuggestNumber] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };
  const handleAutoSuggestCheckboxChange = (event) => {
    setIsAutoSuggestNumber(event.target.checked);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const nextTab = () => {
    if (activeTab < 2) {
      handleTabChange(activeTab + 1);
    }
  };

  const previousTab = () => {
    if (activeTab > 0) {
      handleTabChange(activeTab - 1);
    }
  };

  const handleChange = (event) => {
    setAreaCode(event.target.value);
  };

  const handleSearchNumber = async () => {
    // Make API call here
    console.log("Making API call with area code:", areaCode);

    console.log("actionMe calling!!!");
    // Set Loader True
    setSearchNumberLoader(true);
    try {
      const request = {
        country_code: "US",
        area_code: areaCode,
      };
      let res = await axios.post(
        `${TWILLIO_BASE_URL}/numbers/search_number`,
        request
      );
      // Set Loader False
      setSearchNumberLoader(false);
      console.log({ res });
      setNumberResult(res.data);
    } catch (error) {
      console.log(error, 5);
      message.error("Data not available!");
      // Set Loader False
      setSearchNumberLoader(false);
    }
  };

  const handleNumberChange = (selectedOption) => {
    setSelectedNumbers(selectedOption);
  };

  // Get System Config
  useEffect(() => {
    getSystemConfig();
  }, []);
  const getSystemConfig = async () => {
    // Set Loader True
    setGetSystemConfigLoader(true);
    try {
      const request = {
        country_code: "US",

        area_code: areaCode,
      };
      let res = await axios.get(
        `${TWILLIO_BASE_URL}/numbers/get_system_config`
      );
      // Set Loader False
      setGetSystemConfigLoader(false);
      console.log({ res });
      setSystemConfig(res.data);
    } catch (error) {
      console.log(error, 5);
      // Set Loader False
      setGetSystemConfigLoader(false);
    }
  };
  // Get System Config End

  const handleRemoveSelectedNumber = (index) => {
    const newNumbers = [...selectedNumbers];
    newNumbers.splice(index, 1);
    setSelectedNumbers(newNumbers);
  };

  useEffect(() => {
    console.log({ selectedNumbers });
  }, [selectedNumbers]);

  const handleBuyNumber = async () => {
    // Set Loader True
    setBuyNumberLoader(true);
    try {
      const request = {
        number: selectedNumbers.map((n) => n.value),
        //number_area_code: areaCode ? areaCode : "0",
        number_country_code: 1,
        number_city_code: "251",
        number_type: "FAX",
        number_status: "enabled",
        number_monthly_fees: systemConfig.find(
          (s) => s.config_item === "BUY_NUMBER"
        ).value,
        number_setup_fees: systemConfig.find(
          (s) => s.config_item === "SETUP_FEES"
        ).value,
        provider_Name: "TWILIO",
        user_ID: user.user_ID,
        currency: "USD",
        payment_method: "Wallet",
      };
      let res = await axios.post(
        `${TWILLIO_BASE_URL}/numbers/buy_number`,
        request
      );
      // Set Loader False
      setBuyNumberLoader(false);
      console.log({ res });
      if (
        res.data.successfulPurchases &&
        res.data.successfulPurchases.length > 0
      ) {
        message.success(
          `Congratulations! ${res.data.successfulPurchases.length} New number(s) acquired.`
        );
        navigate("/phone-numbers-list");
      } else if (res.data.code == 201) {
        message.error(res.data.status);
      }
    } catch (error) {
      console.log(error, 5);

      message.error(
        "Oops, Something went wrong! Please try again after later."
      );
      // Set Loader False
      setBuyNumberLoader(false);
    }
  };

  useEffect(() => {
    if (isAutoSuggestNumber) {
      setNumberResult(null);
      setSelectedNumbers([]);
      handleSearchNumber();
      setAreaCode("");
    } else {
      setNumberResult(null);
      setSelectedNumbers([]);
    }
  }, [isAutoSuggestNumber]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Phone Numbers</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="card-title mb-0">Wizard with Progressbar</h4>
                </div>
                <div className="card-body">
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li className="nav-item" onClick={() => setActiveTab(0)}>
                        <span
                          className={
                            activeTab === 0 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Seller Details"
                          >
                            <i className="bx bx-list-ul"></i>
                          </div>
                        </span>
                      </li>
                      <li className="nav-item" onClick={() => setActiveTab(1)}>
                        <span
                          className={
                            activeTab === 1 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Company Document"
                          >
                            <i className="bx bx-book-bookmark"></i>
                          </div>
                        </span>
                      </li>
                      <li className="nav-item" onClick={() => setActiveTab(2)}>
                        <span
                          className={
                            activeTab === 2 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Bank Details"
                          >
                            <i className="bx bxs-bank"></i>
                          </div>
                        </span>
                      </li>
                    </ul>

                    {/* wizard-nav */}
                    <div id="bar" className="progress mt-4">
                      <div
                        className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                        style={{
                          width: `${(activeTab + 1) * 33.3333}%`,
                        }}
                      ></div>
                    </div>
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div
                        className={`tab-pane ${activeTab === 0 && "active"}`}
                        id="progress-seller-details"
                      >
                        <div className="text-center mb-4">
                          <h5>Select Country</h5>
                          <p className="card-title-desc">
                            Fill all information below
                          </p>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="progresspill-firstname-input">
                                  Country
                                </label>
                                <select
                                  className="form-select"
                                  value={selectedCountry}
                                >
                                  <option>Select Country</option>
                                  <option value={"USA"}>USA</option>
                                  {/* <option>India</option>
                                  <option>UAE</option>                          
                                  <option>AUS</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isAutoSuggestNumber}
                                  onChange={handleAutoSuggestCheckboxChange}
                                  id="agreeCheckbox"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="agreeCheckbox"
                                >
                                  Auto Suggest Number
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* SELECT PROVIDER DROPDOWN */}
                          {/* <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="progresspill-email-input">
                                  Select Provider
                                </label>
                                <select className="form-select">                                  
                                  <option>Twillio</option>                                  
                                </select>
                              </div>
                            </div>
                          </div> */}
                        </form>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li
                            className={
                              activeTab === 0 ? "next" : "next disabled"
                            }
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={nextTab}
                            >
                              Next <i className="bx bx-chevron-right ms-1"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 1 && "active"}`}
                        id="progress-company-document"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Find Fax Numbers</h5>
                            <p className="card-title-desc">
                              Find Available Fax Numbers by Area Code
                            </p>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Area Code
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="progresspill-namecard-input"
                                    value={areaCode}
                                    onChange={handleChange}
                                    placeholder="Enter area code"
                                    onBlur={handleSearchNumber}
                                    disabled={isAutoSuggestNumber}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                {/* DEFAULT Nothing */}
                                {/* On Blur input search for records */}
                                {searchNumberLoader && (
                                  <div className="d-flex align-items-center mt-1 mb-2">
                                    <h6>Searching number...</h6>
                                    <div
                                      className="spinner-border spinner-border-sm mx-2"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {/* On Blur input search for records END */}

                                {/* IF Result found then show dropdown  */}
                                {/* IF Result not found then show number not available alert  */}
                                {!searchNumberLoader &&
                                  numberResult &&
                                  numberResult.length < 1 && (
                                    <div
                                      className="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                    >
                                      <strong>Number Not Found!</strong> Please{" "}
                                      <a
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSearchNumber();
                                        }}
                                      >
                                        try again
                                      </a>{" "}
                                      .
                                    </div>
                                  )}
                                {/* IF Result not found then show number not available alert END  */}

                                <div className="mb-3">
                                  <label
                                    htmlFor="choices-multiple-remove-button"
                                    className="form-label font-size-13 text-muted"
                                  >
                                    Add number selection
                                  </label>
                                  <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={selectedNumbers}
                                    isMulti
                                    isDisabled={
                                      searchNumberLoader ||
                                      !numberResult ||
                                      numberResult.length < 1
                                    }
                                    onChange={handleNumberChange}
                                    options={
                                      numberResult &&
                                      numberResult.length > 0 &&
                                      numberResult.map((num) => ({
                                        label: num.phone_number,
                                        value: num.phone_number,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li
                              className={
                                activeTab === 1
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button>
                            </li>
                            <li
                              className={
                                activeTab === 2 ? "next" : "next disabled"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={nextTab}
                                disabled={selectedNumbers.length < 1}
                              >
                                Next{" "}
                                <i className="bx bx-chevron-right ms-1"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 2 && "active"}`}
                        id="progress-bank-detail"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Order Confirmation</h5>
                            <p className="card-title-desc">
                              Review Your Selection
                            </p>
                          </div>
                          <form>
                            <div className="table-responsive">
                              <table className="table align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Number</th>
                                    <th>Monthly Fee</th>
                                    <th>Setup Charges</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedNumbers &&
                                    selectedNumbers.length > 0 &&
                                    selectedNumbers.map((num, index) => (
                                      <tr key={index}>
                                        <th scope="row">{num.value}</th>
                                        <td>
                                          $
                                          {
                                            systemConfig.find(
                                              (s) =>
                                                s.config_item === "BUY_NUMBER"
                                            ).value
                                          }
                                        </td>
                                        <td>
                                          $
                                          {
                                            systemConfig.find(
                                              (s) =>
                                                s.config_item === "SETUP_FEES"
                                            ).value
                                          }
                                        </td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              handleRemoveSelectedNumber(index)
                                            }
                                            type="button"
                                            className="btn btn-soft-danger waves-effect waves-light"
                                          >
                                            <i className="fa fa-close font-size-16 align-middle"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="form-check mt-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isAgreed}
                                onChange={handleCheckboxChange}
                                id="agreeCheckbox"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="agreeCheckbox"
                              >
                                I agree to the{" "}
                                <a
                                  onClick={(e) => e.preventDefault()}
                                  data-bs-toggle="modal"
                                  data-bs-target="#myModal"
                                  href="#"
                                >
                                  terms and conditions
                                </a>{" "}
                              </label>
                            </div>
                          </form>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li
                              className={
                                activeTab === 2
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button>
                            </li>
                            <li className="float-end">
                              {buyNumberLoader ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={
                                    !isAgreed || selectedNumbers.length < 1
                                  }
                                >
                                  <div
                                    className="spinner-border spinner-border-sm mx-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleBuyNumber}
                                  disabled={
                                    !isAgreed || selectedNumbers.length < 1
                                  }
                                >
                                  Buy Number
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      <div className="modal fade" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">Terms and Conditions</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {/* Modal Body */}
            <div className="modal-body">
              <p>These are the terms and conditions.</p>
            </div>
            {/* Modal Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(FaxPhoneNumbers);
