import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";

const AddNewList = () => {
  const [formData, setFormData] = useState({
    listName: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    listName: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...formErrors };

    if (formData.listName.trim() === "") {
      newErrors.listName = "List Name is required";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const onDrop = (acceptedFiles) => {
    // You can add logic to handle the uploaded files here
    setUploadedFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Define accepted file types (e.g., images)
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, handle submission here
      console.log("Form data submitted:", formData);
      console.log("Uploaded files:", uploadedFiles);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Add New List</h4>
                <div className="page-title-right">
                  <DefaultView />
                  <div className="d-flex justify-content-sm-end pt-20">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <Link to="/lists" className="btn btn-primary backbtn">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-6">
                        <h4 className="card-title">Responsive table</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3 compose-section">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="listName" className="form-label">
                                List Name
                              </label>
                              <input
                                className={`form-control ${
                                  formErrors.listName ? "is-invalid" : ""
                                }`}
                                type="text"
                                id="listName"
                                name="listName"
                                placeholder="Enter List Name"
                                value={formData.listName}
                                onChange={handleInputChange}
                              />
                              {formErrors.listName && (
                                <div className="invalid-feedback">
                                  {formErrors.listName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="description"
                                className="form-label"
                              >
                                Description
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="description"
                                name="description"
                                placeholder="Enter Description"
                                value={formData.description}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Upload Files</label>
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                              </div>
                              <h5>Drop files here or click to upload.</h5>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4 mb-4">
                <div className="right-side-btns">
                  <div className="d-flex justify-content-sm-end">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <Link to="lists.html" className="btn btn-primary backbtn">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewList;
