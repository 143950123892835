import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContactTableRow from "../components/ContactTableRow";
import DefaultView from "../components/DefaultView";

const records = [
  {
    id: "01",
    name: "Abdul Rehman",
    description: "Lorem Ipsum is simply dummy text.",
    date: "10:00am 27-Dec-2022",
    time: "6:00 pm",
  },
  {
    id: "02",
    name: "John Doe",
    description: "Lorem Ipsum is another example text.",
    date: "9:30am 28-Dec-2022",
    time: "5:30 pm",
  },
  // Add 19 more data objects below
  {
    id: "03",
    name: "Alice Smith",
    description: "This is a test description.",
    date: "2:15pm 29-Dec-2022",
    time: "3:45 pm",
  },
  {
    id: "04",
    name: "Emma Johnson",
    description: "Some random description here.",
    date: "4:30pm 30-Dec-2022",
    time: "8:00 pm",
  },
  {
    id: "05",
    name: "Michael Brown",
    description: "Lorem Ipsum is widely used.",
    date: "11:45am 31-Dec-2022",
    time: "7:30 pm",
  },
  {
    id: "06",
    name: "Sophia Davis",
    description: "Description for record 06.",
    date: "8:00am 1-Jan-2023",
    time: "4:15 pm",
  },
  {
    id: "07",
    name: "Daniel Wilson",
    description: "Sample description for record 07.",
    date: "3:20pm 2-Jan-2023",
    time: "9:45 pm",
  },
  {
    id: "08",
    name: "Olivia Lee",
    description: "Another description for record 08.",
    date: "1:00pm 3-Jan-2023",
    time: "6:30 pm",
  },
  {
    id: "09",
    name: "James Martin",
    description: "Description for record 09.",
    date: "12:45pm 4-Jan-2023",
    time: "5:00 pm",
  },
  {
    id: "10",
    name: "Ava White",
    description: "Sample description for record 10.",
    date: "9:15am 5-Jan-2023",
    time: "7:45 pm",
  },
  // Continue adding more data objects as needed
];

const Contacts = () => {
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Contact List</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="addNewBtn pb-20">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-sm-end">
                  <Link to="/add-new-list" className="btn btn-primary">
                    Add New List
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card">
                  <div className=" card-header">
                    <div className="row responsSearch">
                      <div className="col-sm-6">
                        <h4 className="card-title">Responsive table</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      <div
                        id="datatable-buttons_filter"
                        className="col-sm-4 dataTables_filter"
                      >
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            aria-controls="datatable-buttons"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-editable table-nowrap align-middle table-edits">
                        <thead>
                          <tr style={{ cursor: "pointer" }}>
                            <th>List ID</th>
                            <th>List Name</th>
                            <th>Description</th>
                            <th>Last Updated</th>
                            <th>Execution Time</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRecords.map((contact) => (
                            <ContactTableRow
                              key={contact.id}
                              id={contact.id}
                              name={contact.name}
                              description={contact.description}
                              date={contact.date}
                              time={contact.time}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* card */}
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="datatable-buttons_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable-buttons_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="datatable-buttons_previous"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={0}
                            tabIndex={0}
                            className="page-link"
                          >
                            Previous
                          </Link>
                        </li>
                        <li className="paginate_button page-item active">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            1
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={2}
                            tabIndex={0}
                            className="page-link"
                          >
                            2
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={3}
                            tabIndex={0}
                            className="page-link"
                          >
                            3
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={4}
                            tabIndex={0}
                            className="page-link"
                          >
                            4
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={5}
                            tabIndex={0}
                            className="page-link"
                          >
                            5
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={6}
                            tabIndex={0}
                            className="page-link"
                          >
                            6
                          </Link>
                        </li>
                        <li
                          className="paginate_button page-item next"
                          id="datatable-buttons_next"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={7}
                            tabIndex={0}
                            className="page-link"
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* end Col */}
            </div>
            {/* end row */}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>{" "}
      {/* container-fluid */}
    </div>
  );
};

export default Contacts;
