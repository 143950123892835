import axios from "axios";
import * as actionTypes from "../action";
import { setAuthToken } from "../../utils/helper";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const actionGetFaxCover = () => async (dispatch, getState) => {
  console.log("actionGetFaxCover");
  dispatch({ type: actionTypes.FAX_COVER_DATA_LOADER, payload: true });
  try {
    let res = await axios.get(`${BASE_URL}/fax-cover`);
    dispatch({ type: actionTypes.FAX_COVER_DATA_LOADER, payload: false });
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_COVER_DATA,
        payload: res.data.result.data.cover,
      });
    } else {
      // TODO: need to redirect to login service
      console.log(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message, 5);
    dispatch({ type: actionTypes.FAX_COVER_DATA_LOADER, payload: false });
  }
};

export const actionAddFaxCover =
  (data, navigate) => async (dispatch, getState) => {
    console.log("actionAddFaxCover");
    dispatch({ type: actionTypes.ADD_FAX_COVER_LOADER, payload: true });
    try {
      let res = await axios.post(`${BASE_URL}/fax-cover/add-fax-cover`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      });
      dispatch({ type: actionTypes.ADD_FAX_COVER_LOADER, payload: false });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        dispatch({
          type: actionTypes.ADD_FAX_COVER_DATA,
          payload: res.data.result,
        });
        message.success("Fax Cover Added Sucessfully");
        navigate && navigate("/fax-covers");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.ADD_FAX_COVER_LOADER, payload: false });
    }
  };

export const actionUpdateFaxCover = (id) => async (dispatch, getState) => {
  console.log("actionAddFaxCover");
  dispatch({ type: actionTypes.UPDATE_FAX_COVER_LOADER, payload: true });
  try {
    let res = await axios.delete(`${BASE_URL}/fax-cover/set-fax-cover/${id}`);
    dispatch({ type: actionTypes.UPDATE_FAX_COVER_LOADER, payload: false });
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.UPDATE_FAX_COVER_DATA,
        payload: res.data.result,
      });
      message.success("Fax Cover Updated Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.UPDATE_FAX_COVER_LOADER, payload: false });
  }
};

export const actionDeleteFaxCover = (id) => async (dispatch, getState) => {
  console.log("actionDeleteFaxCover");
  dispatch({ type: actionTypes.FAX_COVER_DELETE_LOADER, payload: id });
  try {
    let res = await axios.delete(`${BASE_URL}/fax-cover/del-fax-cover/${id}`);
    dispatch({ type: actionTypes.FAX_COVER_DELETE_LOADER, payload: 0 });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_COVER_DELETE,
        payload: id,
      });
      message.success("Fax Cover Deleted Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.FAX_COVER_DELETE_LOADER, payload: 0 });
  }
};

export const actionGetFaxHeader = () => async (dispatch, getState) => {
  console.log("actionGetFaxHeader");
  dispatch({ type: actionTypes.FAX_HEADER_DATA_LOADER, payload: true });
  try {
    let res = await axios.get(`${BASE_URL}/fax-header/get-header`);
    dispatch({ type: actionTypes.FAX_HEADER_DATA_LOADER, payload: false });
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_HEADER_DATA,
        payload: res.data.result.data.header,
      });
    } else {
      // TODO: need to redirect to login service
      console.log(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message, 5);
    dispatch({ type: actionTypes.FAX_HEADER_DATA_LOADER, payload: false });
  }
};

export const actionAddFaxHeader =
  (data, navigate) => async (dispatch, getState) => {
    console.log("actionAddFaxHeader");
    dispatch({ type: actionTypes.ADD_FAX_HEADER_LOADER, payload: true });
    try {
      let res = await axios.post(`${BASE_URL}/fax-header/add-header`, data);
      dispatch({ type: actionTypes.ADD_FAX_HEADER_LOADER, payload: false });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        message.success("Fax Header Added Sucessfully");
        navigate && navigate("/fax-headers");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.ADD_FAX_HEADER_LOADER, payload: false });
    }
  };

export const actionUpdateFaxHeader =
  (id, data) => async (dispatch, getState) => {
    console.log("actionAddFaxHeader");
    dispatch({ type: actionTypes.UPDATE_FAX_HEADER_LOADER, payload: id });
    try {
      let res = await axios.post(
        `${BASE_URL}/fax-header/set-header/${id}`,
        data
      );
      dispatch({ type: actionTypes.UPDATE_FAX_HEADER_LOADER, payload: 0 });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        dispatch({
          type: actionTypes.UPDATE_FAX_HEADER_DATA,
          payload: { data, id },
        });
        message.success("Fax Header Updated Sucessfully");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.UPDATE_FAX_HEADER_LOADER, payload: 0 });
    }
  };

export const actionDeleteFaxHeader = (id) => async (dispatch, getState) => {
  console.log("actionDeleteFaxHeader");
  dispatch({ type: actionTypes.FAX_HEADER_DELETE_LOADER, payload: id });
  try {
    let res = await axios.delete(`${BASE_URL}/fax-header/del-header/${id}`);
    dispatch({ type: actionTypes.FAX_HEADER_DELETE_LOADER, payload: 0 });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_HEADER_DELETE,
        payload: id,
      });
      message.success("Fax Header Deleted Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.FAX_HEADER_DELETE_LOADER, payload: 0 });
  }
};

export const actionGetFaxBlacklist = () => async (dispatch, getState) => {
  console.log("actionGetFaxBlacklist");
  dispatch({ type: actionTypes.FAX_BLACKLIST_DATA_LOADER, payload: true });
  try {
    let res = await axios.get(`${BASE_URL}/fax-blacklist/get-blacklist`);
    dispatch({ type: actionTypes.FAX_BLACKLIST_DATA_LOADER, payload: false });
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_BLACKLIST_DATA,
        payload: res.data.result.data.blacklist,
      });
    } else {
      // TODO: need to redirect to login service
      console.log(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message, 5);
    dispatch({ type: actionTypes.FAX_BLACKLIST_DATA_LOADER, payload: false });
  }
};

export const actionAddFaxBlacklist =
  (data, navigate) => async (dispatch, getState) => {
    console.log("actionAddFaxBlacklist", data);
    dispatch({ type: actionTypes.ADD_FAX_BLACKLIST_LOADER, payload: true });
    try {
      let res = await axios.post(
        `${BASE_URL}/fax-blacklist/add-blacklist`,
        data
      );
      dispatch({ type: actionTypes.ADD_FAX_BLACKLIST_LOADER, payload: false });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        dispatch({
          type: actionTypes.ADD_FAX_BLACKLIST_DATA,
          payload: res.data.result,
        });
        message.success("Fax Blacklist Added Sucessfully");
        navigate && navigate("/fax-blacklist");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.ADD_FAX_BLACKLIST_LOADER, payload: false });
    }
  };

export const actionUpdateFaxBlacklist =
  (data, id) => async (dispatch, getState) => {
    console.log("actionAddFaxBlacklist");
    dispatch({ type: actionTypes.UPDATE_FAX_BLACKLIST_LOADER, payload: id });
    try {
      let res = await axios.post(
        `${BASE_URL}/fax-blacklist/set-blacklist/${id}`,
        data
      );
      dispatch({
        type: actionTypes.UPDATE_FAX_BLACKLIST_LOADER,
        payload: 0,
      });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        dispatch({
          type: actionTypes.UPDATE_FAX_BLACKLIST_DATA,
          payload: { data, id },
        });
        message.success("Fax Blacklist Updated Sucessfully");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({
        type: actionTypes.UPDATE_FAX_BLACKLIST_LOADER,
        payload: 0,
      });
    }
  };

export const actionDeleteFaxBlacklist = (id) => async (dispatch, getState) => {
  console.log("actionDeleteFaxBlacklist");
  dispatch({ type: actionTypes.FAX_BLACKLIST_DELETE_LOADER, payload: id });
  try {
    let res = await axios.delete(
      `${BASE_URL}/fax-blacklist/del-blacklist/${id}`
    );
    dispatch({ type: actionTypes.FAX_BLACKLIST_DELETE_LOADER, payload: 0 });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.FAX_BLACKLIST_DELETE,
        payload: id,
      });
      message.success("Fax Blacklist Deleted Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.FAX_BLACKLIST_DELETE_LOADER, payload: 0 });
  }
};

// ALERT START HERE
export const actionGetAlerts = () => async (dispatch, getState) => {
  console.log("actionGetAlerts calling");
  dispatch({ type: actionTypes.GET_ALERTS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/fax-alerts`);
    console.log(result.data.result.status === "OK");
    dispatch({
      type: actionTypes.GET_ALERTS_LOADER,
      payload: false,
    });
    if (result.data.result.status === "OK") {
      dispatch({
        type: actionTypes.GET_ALERTS_DATA,
        payload: result.data.result.data.alert,
      });
      message.success(result.data.message);
    } else if (result.data.result.status === "ERR") {
      // Failure response
      message.error(result.data.error.error_message);
      console.log("Hello Error");
    } else {
      dispatch({
        type: actionTypes.GET_ALERTS_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
    dispatch({
      type: actionTypes.GET_ALERTS_LOADER,
      payload: false,
    });
  }
};

export const actionDeleteAlert = (id) => async (dispatch, getState) => {
  console.log("actionDeleteFaxCover");
  dispatch({ type: actionTypes.ALERT_DELETE_LOADER, payload: id });
  try {
    let res = await axios.delete(`${BASE_URL}/fax-alerts/del-alert/${id}`);
    dispatch({ type: actionTypes.ALERT_DELETE_LOADER, payload: false });
    if (parseInt(res.data.http_status_code) === 200) {
      dispatch({
        type: actionTypes.ALERT_DELETE,
        payload: id,
      });
      message.success("Fax Cover Deleted Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.ALERT_DELETE_LOADER, payload: false });
  }
};

export const actionUpdateAlert = (id, data) => async (dispatch, getState) => {
  console.log("actionUpdateAlert");
  dispatch({ type: actionTypes.UPDATE_ALERT_LOADER, payload: id });
  try {
    let res = await axios.post(
      `${BASE_URL}/fax-alerts/update-alert/${parseInt(id)}`,
      data
    );
    dispatch({ type: actionTypes.UPDATE_ALERT_LOADER, payload: false });
    console.log(res.data);
    console.log({ res });
    if (parseInt(res.data.http_status_code) === 201) {
      dispatch({
        type: actionTypes.UPDATE_ALERT_DATA,
        payload: { id, ...data },
      });
      message.success("Fax Cover Updated Sucessfully");
    } else {
      console.log(res.data.error.error_message);
      message.error(res.data.error.error_message);
    }
  } catch (error) {
    console.log(error.response.data.message);
    message.error(error.response.data.message);
    dispatch({ type: actionTypes.UPDATE_ALERT_LOADER, payload: false });
  }
};

export const actionCreateAlert =
  (data, navigate) => async (dispatch, getState) => {
    console.log("actionAddFaxCover");
    dispatch({ type: actionTypes.CREATE_ALERT_LOADER, payload: true });
    try {
      let res = await axios.post(`${BASE_URL}/fax-alerts/add-alert`, data);
      dispatch({ type: actionTypes.CREATE_ALERT_LOADER, payload: false });
      console.log(res.data);
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 201) {
        dispatch({
          type: actionTypes.CREATE_ALERT_DATA,
          payload: res.data.result,
        });
        message.success("Fax Cover Created Sucessfully");
        navigate && navigate("/fax-alerts");
      } else {
        console.log(res.data.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
      dispatch({ type: actionTypes.CREATE_ALERT_LOADER, payload: false });
    }
  };

export const actionGetFromNumbers = () => async (dispatch, getState) => {
  console.log("actionGetFromNumbers calling");
  dispatch({ type: actionTypes.GET_FROM_NUMBERS_LOADER, payload: true });

  try {
    let response = await axios.get(`${BASE_URL}/inbox/get-from-numbers`);

    dispatch({
      type: actionTypes.GET_FROM_NUMBERS_LOADER,
      payload: false,
    });

    if (response.data.result.status === "OK") {
      dispatch({
        type: actionTypes.GET_FROM_NUMBERS_DATA,
        payload: response.data.result.data,
      });
      message.success(response.data.message);
    } else if (response.data.result.status === "ERR") {
      message.error(response.data.error.error_message);
    } else {
      dispatch({
        type: actionTypes.GET_FROM_NUMBERS_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error("An error occurred");
    }
    dispatch({
      type: actionTypes.GET_FROM_NUMBERS_LOADER,
      payload: false,
    });
  }
};
