import React, { useState } from "react";

const ContactTableRow = ({ id, name, description, date, time }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    id,
    name,
    description,
    date,
    time,
    status: true, // Default status
  });

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // You can implement the save functionality here
    // For now, let's just print the updated data to the console
    console.log("Updated Data:", data);
    setIsEditing(false);
  };

  const handleRowDoubleClick = () => {
    setIsEditing(true);
  };

  return (
    <tr
      data-id={data.id}
      style={{ cursor: "pointer" }}
      onDoubleClick={handleRowDoubleClick}
    >
      <td data-field="id" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.id}
            onChange={(e) => setData({ ...data, id: e.target.value })}
          />
        ) : (
          data.id
        )}
      </td>
      <td data-field="name" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        ) : (
          data.name
        )}
      </td>
      <td data-field="description" width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
        ) : (
          data.description
        )}
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.date}
            onChange={(e) => setData({ ...data, date: e.target.value })}
          />
        ) : (
          data.date
        )}
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <input
            className="form-control"
            type="text"
            value={data.time}
            onChange={(e) => setData({ ...data, time: e.target.value })}
          />
        ) : (
          data.time
        )}
      </td>
      <td width={"10%"}>
        <div className="square-switch">
          <input
            type="checkbox"
            id="square-switch1"
            switch="bool"
            checked={data.status}
            onChange={(e) => setData({ ...data, status: e.target.checked })}
          />
          <label
            htmlFor="square-switch1"
            data-on-label="Yes"
            data-off-label="No"
          />
        </div>
      </td>
      <td width={"10%"}>
        {isEditing ? (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            title="Save"
            onClick={handleSaveClick}
          >
            <i className="fas fa-save" title="Save" />
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm edit"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" title="Edit" />
          </button>
        )}

        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          style={{ marginLeft: 5 }}
        >
          <i className="fas fa-trash-alt" title="Delete" />
        </button>
      </td>
    </tr>
  );
};

export default ContactTableRow;
