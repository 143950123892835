import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import ListsTableRow from "../components/ListsTableRow";

const records = [
  {
    id: "01",
    name: "Abdul Rehman",
    number: "9988776655",
    email: "admin@gmail.com",
    lastUpdated: "10:00am 27-Dec-2022",
    status: true,
  },
  {
    id: "02",
    name: "John Doe",
    number: "1234567890",
    email: "johndoe@example.com",
    lastUpdated: "9:30am 28-Dec-2022",
    status: false,
  },
  {
    id: "03",
    name: "Alice Smith",
    number: "5555555555",
    email: "alice.smith@example.com",
    lastUpdated: "2:15pm 29-Dec-2022",
    status: true,
  },
  {
    id: "04",
    name: "Emma Johnson",
    number: "9876543210",
    email: "emma.johnson@example.com",
    lastUpdated: "4:30pm 30-Dec-2022",
    status: false,
  },
  {
    id: "05",
    name: "Michael Brown",
    number: "7777777777",
    email: "michael.brown@example.com",
    lastUpdated: "11:45am 31-Dec-2022",
    status: true,
  },
  {
    id: "06",
    name: "Sophia Davis",
    number: "6666666666",
    email: "sophia.davis@example.com",
    lastUpdated: "8:00am 1-Jan-2023",
    status: false,
  },
  {
    id: "07",
    name: "Daniel Wilson",
    number: "4444444444",
    email: "daniel.wilson@example.com",
    lastUpdated: "3:20pm 2-Jan-2023",
    status: true,
  },
  {
    id: "08",
    name: "Olivia Lee",
    number: "2222222222",
    email: "olivia.lee@example.com",
    lastUpdated: "1:00pm 3-Jan-2023",
    status: false,
  },
  {
    id: "09",
    name: "James Martin",
    number: "9999999999",
    email: "james.martin@example.com",
    lastUpdated: "12:45pm 4-Jan-2023",
    status: true,
  },
  {
    id: "10",
    name: "Ava White",
    number: "8888888888",
    email: "ava.white@example.com",
    lastUpdated: "9:15am 5-Jan-2023",
    status: false,
  },
];

const Lists = () => {
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const filtered = records.filter((record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchText]);
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Lists</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-12">
              {/* 
              <div class="addNewBtn mb-4">
                  <div class="row">
                      <div class="col-sm-12">
                          <div class="d-flex justify-content-sm-end">
                              <button class="btn btn-primary">Save</button>
                              <button class="btn btn-primary back_button">Back</button>
                          </div>
                      </div>
                  </div>
              </div>
              */}
              {/* Right Sidebar */}
              <div className="mb-3 compose-section">
                <div className="card marginExtra">
                  <div className="card-header">
                    <div className="row responsSearch">
                      <div className="col-md-6">
                        <h4 className="card-title">Responsive table</h4>
                        <p className="card-title-desc">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                      </div>
                      {/* 
                      <div class="col-sm-6">
                          <div class="d-flex justify-content-sm-end">
                              <Link to="addList.html" class="btn btn-primary">Add New List</Link>
                          </div>
                      </div>
                      */}
                      <div
                        id="datatable-buttons_filter"
                        className="col-sm-4 dataTables_filter"
                      >
                        <div className="d-flex align-items-start flex-column">
                          <label>Search:</label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search item"
                            aria-controls="datatable-buttons"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-editable table-nowrap align-middle table-edits">
                        <thead>
                          <tr style={{ cursor: "pointer" }}>
                            <th>Contact ID</th>
                            <th>Contact Name</th>
                            <th>Contact Number</th>
                            <th>Contact Email</th>
                            <th>Last Updated</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRecords.map((record) => (
                            <ListsTableRow
                              key={record.id}
                              id={record.id}
                              name={record.name}
                              number={record.number}
                              email={record.email}
                              lastUpdated={record.lastUpdated}
                              status={record.status}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* card */}
                {/* <div class="card-header marginBottom">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="d-flex justify-content-sm-end">
                                                <button class="btn btn-primary">Save</button>
                                                <button class="btn btn-primary back_button">Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="datatable-buttons_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="datatable-buttons_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="datatable-buttons_previous"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={0}
                            tabIndex={0}
                            className="page-link"
                          >
                            Previous
                          </Link>
                        </li>
                        <li className="paginate_button page-item active">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            1
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={2}
                            tabIndex={0}
                            className="page-link"
                          >
                            2
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={3}
                            tabIndex={0}
                            className="page-link"
                          >
                            3
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={4}
                            tabIndex={0}
                            className="page-link"
                          >
                            4
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={5}
                            tabIndex={0}
                            className="page-link"
                          >
                            5
                          </Link>
                        </li>
                        <li className="paginate_button page-item ">
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={6}
                            tabIndex={0}
                            className="page-link"
                          >
                            6
                          </Link>
                        </li>
                        <li
                          className="paginate_button page-item next"
                          id="datatable-buttons_next"
                        >
                          <Link
                            to="#"
                            aria-controls="datatable-buttons"
                            data-dt-idx={7}
                            tabIndex={0}
                            className="page-link"
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* end Col */}
            </div>
            {/* end row */}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>{" "}
      {/* container-fluid */}
    </div>
  );
};

export default Lists;
