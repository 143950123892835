import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// import "choices.js/public/assets/styles/choices.min.css";
import Choices from "choices.js";
import DefaultView from "../components/DefaultView";
import { connect } from "react-redux";
import { toast as message } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  actionAddVerifiedNumber,
  actionVerifyNumber,
} from "../store/actions/authAction";

const animatedComponents = makeAnimated();

const AddVerifiedNumber = ({
  user,
  actionAddVerifiedNumber,
  addVerifiedNumberLoader,
  actionVerifyNumber,
  verifyNumberLoader,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedType, setSelectedType] = useState("PHONE");
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    // Regex pattern to match mobile numbers with optional leading '+' and allow backspace
    const mobileNumberPattern = /^\+?[0-9]*$/;

    // Check if the entered value matches the mobile number pattern
    if (inputValue === "" || mobileNumberPattern.test(inputValue)) {
      // If it matches or if the input is empty, update the state with the new number
      setNumber(inputValue);
    }
    // If it doesn't match, you can display an error message or handle it in any other way.
  };

  const handleSubmit = () => {
    actionAddVerifiedNumber(user.user_ID, selectedType, number, nextTab);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const nextTab = () => {
    if (activeTab < 2) {
      handleTabChange(activeTab + 1);
    }
  };

  const previousTab = () => {
    if (activeTab > 0) {
      handleTabChange(activeTab - 1);
    }
  };

  const handleChangeOtp = (e) => setOtp(e.target.value);

  const handleVerifyOtp = () => {
    actionVerifyNumber(user.user_ID, number, otp, nextTab);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">
                  Add Phone / Fax Numbers
                </h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                {/* <div className="card-header">
                  <h4 className="card-title mb-0">
                    Verify Phone / Fax Number to redirect our fax service
                  </h4>
                </div> */}
                <div className="card-body">
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(0)}
                      >
                        <span
                          className={
                            activeTab === 0 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Seller Details"
                          >
                            <i class="fa fa-hashtag"></i>
                          </div>
                        </span>
                      </li>
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(1)}
                      >
                        <span
                          className={
                            activeTab === 1 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Company Document"
                          >
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </div>
                        </span>
                      </li>
                      <li
                        className="nav-item"
                        //onClick={() => setActiveTab(2)}
                      >
                        <span
                          className={
                            activeTab === 2 ? "nav-link active" : "nav-link"
                          }
                          data-toggle="tab"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Bank Details"
                          >
                            <i class="fa fa-check"></i>
                          </div>
                        </span>
                      </li>
                    </ul>

                    {/* wizard-nav */}
                    <div id="bar" className="progress mt-4">
                      <div
                        className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                        style={{
                          width: `${(activeTab + 1) * 33.3333}%`,
                        }}
                      ></div>
                    </div>
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div
                        className={`tab-pane ${activeTab === 0 && "active"}`}
                        id="progress-seller-details"
                      >
                        <div className="text-center mb-4">
                          <h5>Add Phone / Fax Number</h5>
                          <p className="card-title-desc">
                            You will receive a One-Time Password (OTP) based on
                            your choice of phone or fax.
                          </p>
                        </div>
                        {/* BODY 1 */}
                        <form style={{ width: 400, margin: "auto" }}>
                          <div className="form-group">
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="type"
                                  id="mobile"
                                  value="PHONE"
                                  checked={selectedType === "PHONE"}
                                  onChange={handleTypeChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mobile"
                                >
                                  Mobile
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="type"
                                  id="fax"
                                  value="FAX"
                                  checked={selectedType === "FAX"}
                                  onChange={handleTypeChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="fax"
                                >
                                  Fax
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <input
                              type="text"
                              className="form-control"
                              id="number"
                              value={number}
                              onChange={handleNumberChange}
                              placeholder="Enter number e.g +1434568..."
                            />
                          </div>
                        </form>
                        {/* BODY 1 Ends  */}
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li
                            className={
                              activeTab === 0 ? "next" : "next disabled"
                            }
                          >
                            {addVerifiedNumberLoader ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                disabled={true}
                              >
                                <div
                                  className="spinner-border spinner-border-sm mx-2"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={!number}
                              >
                                Next{" "}
                                <i className="bx bx-chevron-right ms-1"></i>
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 1 && "active"}`}
                        id="progress-company-document"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>Verify Phone / Fax Number</h5>
                            <p className="card-title-desc">
                              Make sure your phone or fax device is on and has a
                              stable connection. The OTP should be sent to the
                              number or fax machine registered with Optimove.
                            </p>
                          </div>
                          {/* BODY 2 */}
                          <form style={{ width: 400, margin: "auto" }}>
                            <div className="form-group">
                              <div className="form-group mt-3">
                                <label>OTP sent on {number}</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="number"
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  placeholder="Enter OTP"
                                />
                              </div>
                            </div>
                          </form>
                          {/* BODY 2 Ends  */}
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li
                              className={
                                activeTab === 1
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button>
                            </li>
                            <li
                              className={
                                activeTab === 2 ? "next" : "next disabled"
                              }
                            >
                              {verifyNumberLoader ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={true}
                                >
                                  <div
                                    className="spinner-border spinner-border-sm mx-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleVerifyOtp}
                                  disabled={otp.length < 6}
                                >
                                  Verify
                                  <i className="bx bx-chevron-right ms-1"></i>
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${activeTab === 2 && "active"}`}
                        id="progress-bank-detail"
                      >
                        <div>
                          <div className="text-center mb-4">
                            <h5>{number} Successfully Added </h5>
                            <p className="card-title-desc">
                              Your number has been verified and added
                            </p>
                          </div>
                          {/* BODY 3 */}
                          {/* BODY 3 Ends  */}
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li
                              className={
                                activeTab === 2
                                  ? "previous"
                                  : "previous disabled"
                              }
                            >
                              {/* <button
                                type="button"
                                className="btn btn-primary"
                                onClick={previousTab}
                              >
                                <i className="bx bx-chevron-left me-1"></i>{" "}
                                Previous
                              </button> */}
                            </li>
                            <li className="float-end">
                              <Link
                                to="/add-verified-phone-fax-number"
                                className="btn btn-primary"
                                style={{marginRight:15}}
                              >
                                Add New Number
                              </Link>
                              <Link
                                to="/verified-phone-fax-list"
                                className="btn btn-primary"
                              >
                                View All
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    addVerifiedNumberLoader: state.auth.addVerifiedNumberLoader,
    verifyNumberLoader: state.auth.verifyNumberLoader,
  };
};

export default connect(mapStateToProps, {
  actionAddVerifiedNumber,
  actionVerifyNumber,
})(AddVerifiedNumber);
