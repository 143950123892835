import CryptoJS from "crypto-js";
import React, { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DefaultView from "../components/DefaultView";
import PhoneNumberAccordion from "../components/PhoneNumberAccordion";
import {
  actionMe,
  actionDashboard,
  actionGetMyNumbers,
  actionFaxStats,
} from "../store/actions/authAction";
import { actionGetFaxQueuesCount } from "../store/actions/sentFaxesAction";
import { setAuthToken } from "../utils/helper";
import moment from "moment";

const Home = memo(
  ({
    actionMe,
    user,
    actionDashboard,
    dashboard,
    myNumbers,
    actionGetMyNumbers,
    getMyNumbersLoader,
    actionFaxStats,
    getFaxStatsLoader,
    faxStats,
    actionGetFaxQueuesCount,
    inFaxQueuesCount,
    outFaxQueuesCount,
    getFaxQueuesCountLoader,
  }) => {
    const [searchParams] = useSearchParams();
    const [faxStatFilter, setFaxStatFilter] = useState("1M");
    const userID = searchParams.get("user_ID");
    const token = searchParams.get("token");
    const { inboxTotalUnread, inboxTotalImportant, balance } = dashboard;
    const { totalReceivedFaxes, totalSentFaxes } = faxStats;
    console.log({ userID, token });
    console.log({
      inboxTotalUnread,
      inboxTotalImportant,

      balance,
    });

    if (userID && token) {
      // Encryption key and IV (Initialization Vector)
      const key = CryptoJS.enc.Utf8.parse("MuwdpPoXKQFigWhEO6TaUN5oFPoSm3DP");
      const iv = CryptoJS.enc.Utf8.parse("ttoxHoyErmLzguA1");

      // Decrypt the data using AES-128 decryption and CBC mode
      var decryptedUserIdData = CryptoJS.AES.decrypt(userID, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convert the decrypted data to a string
      var descryptedUserId = decryptedUserIdData.toString(CryptoJS.enc.Utf8);

      // Decrypt the data using AES-128 decryption and CBC mode
      var decryptedTokenData = CryptoJS.AES.decrypt(token, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convert the decrypted data to a string
      var descryptedToken = decryptedTokenData.toString(CryptoJS.enc.Utf8);

      console.log({ descryptedUserId, descryptedToken });
      descryptedToken && localStorage.setItem("authToken", descryptedToken);
      descryptedUserId && localStorage.setItem("authUserId", descryptedUserId);
      setAuthToken(descryptedToken);
      !user && actionMe();
    }

    useEffect(() => {
      user && actionDashboard();
      user && actionGetMyNumbers(user.user_ID);
      user && actionFaxStats(faxStatFilter);
      user && actionGetFaxQueuesCount(user.user_ID);
    }, [user]);

    const chartData = {
      options: {
        labels: ["Faxes Received", "Faxes Sent"],
      },
      series: [
        totalReceivedFaxes ? totalReceivedFaxes : 0,
        totalSentFaxes ? totalSentFaxes : 0,
      ],
      colors: ["#777aca", "#5156be", "#a8aada"],
      chart: {
        type: "pie",
      },
      labels: ["Faxes Received", "Faxes Sent"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      hoverOffset: 4,
    };

    useEffect(() => {
      user && actionFaxStats(faxStatFilter);
    }, [faxStatFilter]);

    return (
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 main_heading">Dashboard</h4>
                  <div className="page-title-right">
                    <DefaultView />
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Inbox</h4>
                      <p className="card-title-desc">
                        Quickly view your important messages.
                      </p>
                    </div>
                    {/* end card header */}
                    <div className="card-body">
                      <div className="d-flex flex-wrap gap-2">
                        <Link to="/inbox">
                          <button type="button" className="btn btn-success">
                            Messages{" "}
                            <span
                              className="badge bg-dark ms-1"
                              style={{ color: "green" }}
                            >
                              {inboxTotalUnread}
                            </span>
                          </button>
                        </Link>
                        <Link to="/inbox/important">
                          <button type="button" className="btn btn-danger">
                            Important{" "}
                            <span
                              className="badge bg-dark ms-1"
                              style={{ color: "green" }}
                            >
                              {inboxTotalImportant}
                            </span>
                          </button>
                        </Link>
                        <Link to="/compose-fax">
                          <button type="button" className="btn btn-primary">
                            Compose Fax
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* end card-body */}
                  </div>
                  {/* end card */}
                </div>
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fax Queues</h4>
                      <p className="card-title-desc">
                        you can directly see faxes being sent or recived in
                        queues.
                      </p>
                    </div>
                    {/* end card header */}
                    <div className="card-body">
                      <div className="d-flex flex-wrap gap-2">
                        <Link to="/queues/IN">
                          <button type="button" className="btn btn-primary">
                            Incoming{" "}
                            {inFaxQueuesCount > 0 && (
                              <span
                                className="badge bg-dark ms-1"
                                style={{ color: "green" }}
                              >
                                {inFaxQueuesCount}
                              </span>
                            )}
                          </button>
                        </Link>
                        <Link to="/queues/OUT">
                          <button type="button" className="btn btn-success">
                            Outgoing{" "}
                            {outFaxQueuesCount > 0 && (
                              <span
                                className="badge bg-dark ms-1"
                                style={{ color: "green" }}
                              >
                                {outFaxQueuesCount}
                              </span>
                            )}
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* end card-body */}
                  </div>
                  {/* end card */}
                </div>
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <Link to="/phone-numbers-list">
                        <h4 className="card-title">Phone Numbers</h4>
                      </Link>
                      <p className="card-title-desc">
                        Your phone number subscription are listed below.
                      </p>
                    </div>
                    {/* end card header */}
                    <div className="card-body">
                      <div className="accordion" id="accordionExample">
                        {getMyNumbersLoader && "Loading..."}
                        {myNumbers.map((data, index) => (
                          <PhoneNumberAccordion
                            key={index}
                            index={index}
                            phoneNumber={data.number}
                            subscriptionDate={moment(
                              data.number_activation_date
                            ).format("MMMM D, YYYY")}
                            nextBillingDate={moment(
                              data.number_billing_date
                            ).format("MMMM D, YYYY")}
                            status={data.number_status}
                          />
                        ))}
                      </div>
                      {/* end accordion */}
                    </div>
                    {/* end card-body */}
                  </div>
                  {/* end card */}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="row">
                  <div className="col-xl-6 col-md-6">
                    {/* card */}
                    <div className="card card-h-100">
                      {/* card body */}
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div
                            className="col-12"
                            style={{ textAlign: "center" }}
                          >
                            <span className="text-muted mb-3 lh-1 d-block text-truncate">
                              My Funds
                            </span>
                            <h4 className="mb-3">
                              $<span className="counter-value">{balance}</span>
                            </h4>
                          </div>
                        </div>
                        <div
                          className="text-nowrap"
                          style={{ textAlign: "center" }}
                        >
                          <span className="badge bg-soft-success text-success">
                            +$20.9k
                          </span>
                          <span
                            className="ms-1 font-size-13"
                            style={{ textAlign: "center" }}
                          >
                            Last balance added.
                          </span>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-6 col-md-6">
                    {/* card */}
                    <div className="card card-h-100">
                      {/* card body */}
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div
                            className="col-12"
                            style={{ textAlign: "center" }}
                          >
                            <span className="text-muted mb-3 lh-1 d-block text-truncate">
                              Free Pages
                            </span>
                            <h4 className="mb-3">
                              <span
                                className="counter-value"
                                data-target={6258}
                              >
                                6258
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div
                          className="text-nowrap"
                          style={{ textAlign: "center" }}
                        >
                          <span className="badge bg-soft-success text-success">
                            2 Referrals
                          </span>
                          <span className="ms-1 font-size-13">
                            Since last week
                          </span>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col*/}
                  <div className="col-xl-12">
                    {/* card */}
                    <div className="card card-h-100">
                      {/* card body */}
                      <div className="card-body">
                        <div className="d-flex flex-wrap align-items-center mb-4">
                          <h5 className="card-title me-2">Fax Statistics</h5>
                          <div className="ms-auto">
                            <div>
                              <button
                                type="button"
                                className={`btn btn-soft-secondary btn-sm ${
                                  faxStatFilter === "ALL" ? "active" : ""
                                }`}
                                style={{ marginRight: 5 }}
                                onClick={() => setFaxStatFilter("ALL")}
                              >
                                ALL
                              </button>
                              <button
                                type="button"
                                className={`btn btn-soft-primary btn-sm ${
                                  faxStatFilter === "1M" ? "active" : ""
                                }`}
                                style={{ marginRight: 5 }}
                                onClick={() => setFaxStatFilter("1M")}
                              >
                                1M
                              </button>
                              <button
                                type="button"
                                className={`btn btn-soft-secondary btn-sm ${
                                  faxStatFilter === "3M" ? "active" : ""
                                }`}
                                style={{ marginRight: 5 }}
                                onClick={() => setFaxStatFilter("3M")}
                              >
                                3M
                              </button>
                              <button
                                type="button"
                                className={`btn btn-soft-secondary btn-sm ${
                                  faxStatFilter === "6M" ? "active" : ""
                                }`}
                                style={{ marginRight: 5 }}
                                onClick={() => setFaxStatFilter("6M")}
                              >
                                6M
                              </button>
                              <button
                                type="button"
                                className={`btn btn-soft-secondary btn-sm ${
                                  faxStatFilter === "1Y" ? "active" : ""
                                }`}
                                style={{ marginRight: 5 }}
                                onClick={() => setFaxStatFilter("1Y")}
                              >
                                1Y
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-sm">
                            <div
                              id="wallet-balance"
                              style={{ minHeight: "209.8px" }}
                            >
                              <ReactApexChart
                                options={chartData.options}
                                series={chartData.series}
                                type={chartData.chart.type}
                                labels={chartData.labels}
                                colors={chartData.colors}
                                responsive={chartData.responsive}
                                height={400}
                                width={400}
                              />
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div style={{ width: 248, height: 211 }} />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </div>
                          <div className="col-sm align-self-center">
                            <div className="mt-4 mt-sm-0">
                              <div>
                                <p className="mb-2">
                                  <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success" />{" "}
                                  Faxes Recived
                                </p>
                                <h6>
                                  <span className="font-size-14 fw-normal">
                                    {totalReceivedFaxes}
                                  </span>
                                </h6>
                              </div>
                              <div className="mt-4 pt-2">
                                <p className="mb-2">
                                  <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                                  Faxes Sent
                                </p>
                                <h6>
                                  <span className="font-size-14 fw-normal">
                                    {totalSentFaxes}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
              </div>
            </div>
            {/* end row*/}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    dashboard: state.auth.dashboard,
    myNumbers: state.auth.myNumbers,
    getMyNumbersLoader: state.auth.getMyNumbersLoader,
    getFaxStatsLoader: state.auth.getFaxStatsLoader,
    faxStats: state.auth.faxStats,
    inFaxQueuesCount: state.sentFax.inFaxQueuesCount,
    outFaxQueuesCount: state.sentFax.outFaxQueuesCount,
    getFaxQueuesCountLoader: state.sentFax.getFaxQueuesCountLoader,
  };
};
export default connect(mapStateToProps, {
  actionMe,
  actionDashboard,
  actionGetMyNumbers,
  actionFaxStats,
  actionGetFaxQueuesCount,
})(Home);
