import React from "react";

function ReferralTableRow({
  name,
  email,
  number,
  date,
  onCheckClick,
  onReinviteClick,
}) {
  return (
    <tr>
      <td>{name}</td>
      <td>{email}</td>
      <td>{number}</td>
      <td>
        <button
          type="button"
          className="btn btn-soft-success waves-effect waves-light"
          onClick={onCheckClick}
        >
          <i className="bx bx-check-double font-size-16 align-middle" />
        </button>
      </td>
      <td>{date}</td>
      <td>
        <button className="btn btn-primary" onClick={onReinviteClick}>
          Reinvite
        </button>
      </td>
    </tr>
  );
}

export default ReferralTableRow;
