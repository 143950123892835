import React, { useState } from "react";
import { Link } from "react-router-dom";
import DefaultView from "../components/DefaultView";

const ReferralInviteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    faxNumber: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!formData.name) {
      validationErrors.name = "Name is required";
    }

    if (!formData.faxNumber) {
      validationErrors.faxNumber = "Fax Number is required";
    }

    if (!formData.emailAddress) {
      validationErrors.emailAddress = "Email Address is required";
    }

    if (!formData.phoneNumber) {
      validationErrors.phoneNumber = "Phone Number is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Handle form submission here (e.g., send data to the server)
      console.log("Form submitted:", formData);
      // Reset the form after submission
      setFormData({
        name: "",
        faxNumber: "",
        emailAddress: "",
        phoneNumber: "",
      });
      setErrors({});
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                <h4 className="mb-sm-0 main_heading">Invite Form</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 compose-section">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                Name
                              </label>
                              <input
                                className={`form-control ${
                                  errors.name ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                              {errors.name && (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="faxNumber" className="form-label">
                                Fax Number
                              </label>
                              <input
                                className={`form-control ${
                                  errors.faxNumber ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Fax Number"
                                name="faxNumber"
                                value={formData.faxNumber}
                                onChange={handleInputChange}
                              />
                              {errors.faxNumber && (
                                <div className="invalid-feedback">
                                  {errors.faxNumber}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="emailAddress"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <input
                                className={`form-control ${
                                  errors.emailAddress ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Email Address"
                                name="emailAddress"
                                value={formData.emailAddress}
                                onChange={handleInputChange}
                              />
                              {errors.emailAddress && (
                                <div className="invalid-feedback">
                                  {errors.emailAddress}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="phoneNumber"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                className={`form-control ${
                                  errors.phoneNumber ? "is-invalid" : ""
                                }`}
                                type="text"
                                placeholder="Enter Phone Number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                              />
                              {errors.phoneNumber && (
                                <div className="invalid-feedback">
                                  {errors.phoneNumber}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      {/* end row */}
                    </div>
                  </div>
                </div>
                {/* card */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-sm-end">
                  <button className="btn btn-primary">Save</button>
                  <Link to="/lists" className="btn btn-primary backbtn">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

export default ReferralInviteForm;
